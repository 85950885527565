import {
    getDashboardData,
    getMypropertiesData,
    getMytenanciesData,
    getMyoffersData,
    getMyappointmentsData,
    postAppointment,
    postForm,
    getProperties,
    getCustomProperties,
    getProperty,
    getNewsItems,
    countNewsItems,
    getMyNegotiatorData,
} from "../api"
import { doLogout } from "../utils"

import { API_ERROR_MESSAGE } from "../constants"

const services = (dispatch, actions) => {
    const handleError = error => {
        if (error.response) {
            if (error.response?.data?.statusCode === 401) doLogout()
            dispatch(
                actions.myaccountActions("API_ERROR", {
                    message: error.response.data.message,
                    status: error.response.status,
                })
            )
        } else {
            dispatch(
                actions.myaccountActions("SERVICE_ERROR", {
                    message: API_ERROR_MESSAGE,
                    status: 500,
                })
            )
        }
    }

    return {
        postForm: payload => {
            dispatch(actions.myaccountActions("FORM_LOADING"))
            postForm(payload)
                .then(res => {
                    dispatch(actions.myaccountActions("FORM_POST_SUCCESS", res))
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getDashboardData: payload => {
            getDashboardData(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions("GET_DASHBOARD_DATA", res.data)
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getMypropertiesData: payload => {
            dispatch(actions.myaccountActions("DATA_LOADING"))
            getMypropertiesData(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions(
                            "GET_MYPROPERTIES_DATA",
                            res.data
                        )
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getMyNegotiatorData: payload => {
            // dispatch(actions.myaccountActions('DATA_LOADING'));
            getMyNegotiatorData(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions(
                            "GET_MY_NEGOTIATOR_DATA",
                            res.data
                        )
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getMytenanciesData: payload => {
            getMytenanciesData(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions(
                            "GET_MYTENANCIES_DATA",
                            res.data
                        )
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getMyoffersData: payload => {
            getMyoffersData(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions("GET_MYOFFERS_DATA", res.data)
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getMyappointmentsData: payload => {
            getMyappointmentsData(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions(
                            "GET_MYAPPOINTMENTS_DATA",
                            res.data
                        )
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        postAppointment: payload => {
            postAppointment(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions(
                            "POST_APPOINTMENTS_SUCCESS",
                            res.data
                        )
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getProperties: payload => {
            dispatch(actions.myaccountActions("DATA_LOADING"))
            getProperties(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions("GET_PROPERTIES", res.data)
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getCustomProperties: payload => {
            dispatch(actions.myaccountActions("DATA_LOADING"))
            getCustomProperties(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions(
                            "GET_CUSTOM_PROPERTIES",
                            res.data
                        )
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getProperty: payload => {
            dispatch(actions.myaccountActions("DATA_LOADING"))
            getProperty(payload)
                .then(res => {
                    dispatch(actions.myaccountActions("GET_PROPERTY", res.data))
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getNewsItems: payload => {
            dispatch(actions.myaccountActions("DATA_LOADING"))
            getNewsItems(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions("GET_NEWS_ITEMS", res.data)
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        countNewsItems: payload => {
            dispatch(actions.myaccountActions("DATA_LOADING"))
            countNewsItems(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions("COUNT_NEWS_ITEMS", res.data)
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getNewsItem: payload => {
            dispatch(actions.myaccountActions("DATA_LOADING"))
            getNewsItems(payload)
                .then(res => {
                    dispatch(
                        actions.myaccountActions("GET_NEWS_ITEM", res.data)
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        updateState: payload => {
            dispatch(actions.myaccountActions("CHANGE_STATE", payload))
        },
    }
}

export default services

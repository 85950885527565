import {
    postForm,
    getAvailabilitySlots,
    getOpenAvailabilitySlots,
    getAvailabilitySlotsByPostcode,
    getActionAllowed,
    getTokenValues,
} from "../api"
import { API_ERROR_MESSAGE } from "../constants"
import { doLogout, storeToken, getUserPersona } from "../utils"

const services = (dispatch, actions) => {
    const handleError = error => {
        if (error.response) {
            if (error.response?.data?.statusCode === 401) doLogout()
            dispatch(
                actions.formActions("API_ERROR", {
                    message: error.response.data.message,
                    status: error.response.status,
                })
            )
        } else {
            dispatch(
                actions.formActions("SERVICE_ERROR", {
                    message: API_ERROR_MESSAGE,
                    status: 500,
                })
            )
        }
    }

    return {
        postForm: payload => {
            let postData = { ...payload }
            let trackData = postData?.trackData

            // leadpro changes
            postData["lead_type"] = "myaccount_leads"

            let leadRole = getUserPersona(postData)
            if (leadRole) postData["lead_role"] = leadRole

            // remove if we pass steps related params
            if (postData?.step) delete postData.step
            if (postData?.nextStep) delete postData.nextStep
            if (postData?.successMessage) delete postData.successMessage
            if (postData?.postActionData) delete postData.postActionData
            if (postData?.trackData) delete postData.trackData

            // let postData = {}
            if (payload?.multipart) {
                let formData = new FormData()
                if (postData?.file) {
                    for (let f of postData?.file) {
                        formData.append("files.attachment", f, f.name) // appending file
                    }

                    delete postData.file
                }
                formData.append("data", JSON.stringify(postData))
                postData = formData
            }

            dispatch(actions.formActions("FORM_LOADING"))
            postForm(postData)
                .then(res => {
                    let response = {
                        ...res,
                    }

                    if (trackData && typeof trackData === "function") {
                        trackData()
                    }

                    if (payload?.step)
                        response["step"] = payload?.step
                            ? payload?.step
                            : "default"
                    if (payload?.nextStep) response["step"] = payload?.nextStep
                    if (payload?.successMessage)
                        response["message"] = payload?.successMessage
                    if (payload?.postActionData)
                        response["postActionData"] = payload?.postActionData

                    dispatch(actions.formActions("FORM_POST_SUCCESS", response))
                })
                .catch(error => {
                    // Login failed
                    if (error.response) {
                        // Login error
                        dispatch(
                            actions.formActions("FORM_POST_ERROR", {
                                message: error.response.data.message,
                                status: error.response.status,
                            })
                        )
                    } else {
                        // Service error
                        dispatch(
                            actions.formActions("FORM_POST_ERROR", {
                                message: API_ERROR_MESSAGE,
                                status: 500,
                            })
                        )
                    }
                })
        },
        formProcessing: payload => {
            dispatch(actions.formActions("FORM_LOADING"))
        },
        resetForm: payload => {
            dispatch(actions.formActions("FORM_REFRESH"))
        },
        changeFormState: payload => {
            dispatch(actions.formActions("CHANGE_FORM_STATE", payload))
        },
        getAvailabilitySlots: payload => {
            dispatch(actions.formActions("FORM_LOADING"))
            getAvailabilitySlots(payload)
                .then(res => {
                    dispatch(actions.formActions("GET_AVAILABILITY_SLOTS", res))
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getOpenAvailabilitySlots: payload => {
            dispatch(actions.formActions("FORM_LOADING"))
            getOpenAvailabilitySlots(payload)
                .then(res => {
                    dispatch(actions.formActions("GET_AVAILABILITY_SLOTS", res))
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getAvailabilitySlotsByPostcode: payload => {
            dispatch(actions.formActions("FORM_LOADING"))
            getAvailabilitySlotsByPostcode(payload)
                .then(res => {
                    dispatch(
                        actions.formActions(
                            "GET_AVAILABILITY_SLOTS_BY_POSTCODE",
                            res
                        )
                    )
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getActionAllowed: payload => {
            dispatch(actions.formActions("FORM_LOADING"))
            getActionAllowed(payload)
                .then(res => {
                    dispatch(actions.formActions("GET_ACTION_ALLOWED", res))
                })
                .catch(error => {
                    handleError(error)
                })
        },
        getTokenValues: payload => {
            dispatch(actions.formActions("FORM_LOADING"))
            getTokenValues(payload)
                .then(res => {
                    if (res?.data?.jwt) {
                        storeToken(res?.data)
                        dispatch(actions.formActions("GET_TOKEN_VALUES", res))
                    } else {
                        dispatch(actions.formActions("GET_TOKEN_EXPIRED", res))
                    }
                })
                .catch(error => {
                    handleError(error)
                })
        },
    }
}

export default services

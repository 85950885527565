// @todo this need to be changed with a generic one, not gatsby specific
import { navigate } from "gatsby"
import { template } from "lodash"

const doRedirect = (url, usePath = false) => {
    if (typeof window !== `undefined`) {
        // looking for external link
        if (url.match(/https|http/)) window.location.href = url
        else if (usePath) window.location.pathname = url
        else navigate(url)
    }
}

// reading params from location search
const useQuery = (location, key) => {
    const query = new URLSearchParams(location.search)
    return query.get(key)
}

// reading params from url
const goBack = () => {
    navigate(-1)
}

const formatString = (string, values) => {
    let compiled = template(string)
    string = compiled(values)

    return string
}

export { doRedirect, useQuery, goBack, formatString }

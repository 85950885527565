import {
  login,
  register,
  updateToken,
  providerLogin,
  forgotPassword,
  resetPassword,
  createPassTrack,
  emailConfirmation,
  getUserProfile,
  getPendingActionCount,
  createUserobject,
  hasPersona,
  updateLastLogin,
  verifyOtpPassword,
  sendLoginOtp,
} from '../api';
import {
  isBrowser,
  apiUrl,
  doLogout,
  storeData,
  getData,
  removeData,
  storeUserData,
  setOtpSentFlag,
  checkIfCanOtpSend,
  setUserSession,
  
} from '../utils';
import { API_ERROR_MESSAGE } from "../constants";
import { storeToken } from '../utils';

import md5 from 'md5';
import { get } from 'lodash'

// Redux store and profile action
import reduxStore from '../../../redux/store';
import { fetchUserProfileSuccess, resetUserProfile, updateProfileState } from '../../../redux/reducers/profileSlice';
import { API_PREFIX } from "../../../constants/urls";

const baseURL = apiUrl();

const services = (dispatch, actions) => {

    const handleError = (error) => {
        if (error.response) {
          if (error.response?.data?.statusCode === 401) doLogout();
          // Login error
          dispatch(
            actions.apiError({
              message: error.response.data.message,
              status: error.response.status
            })
          );
        } else {
          // Service error
          dispatch(
            actions.serviceError({
              message: API_ERROR_MESSAGE,
              status: 500
            })
          );
        }
    }

    const handleAuthError = (error) => {
      
        if (error?.response && error?.response?.data?.statusCode === 401)
          doLogout()
        const errotMsg = get(error, 'response.data.message', false) || get(error, 'response.message', false) || get(error, 'response.data.error.message', false) || API_ERROR_MESSAGE
        if (error.response && errotMsg) {
          // Login error
          dispatch(
            actions.authError({
              message: errotMsg,
              status: error.response.status
            })
          );
        } else {
          // Service error
          dispatch(
            actions.serviceError({
              message: API_ERROR_MESSAGE,
              status: 500
            })
          );
        }
    }

    return ({
      storeData: (key, value) => {
        storeData(key, value);
      },
      getData: (key) => {
        return getData(key);
      },
      removeData: (key) => {
        removeData(key);
      },
      registerWithEmail: (payload) => {
        dispatch(actions.authLoading());
        reduxStore.dispatch(resetUserProfile());
        register(payload)
          .then((res) => {
            // Auto confirm user
            if (res.data.user.confirmed) {
              // Login success
              storeToken(res.data);
              dispatch(actions.authProcess(res));
              reduxStore.dispatch(fetchUserProfileSuccess(res));
            } else {
              // Email verification required
              dispatch(actions.verifyProcess(res));
            }
          })
          .catch((error) => {
            handleAuthError(error);
          });
      },

      loginWithEmail: (payload) => {
        dispatch(actions.authLoading());
        reduxStore.dispatch(resetUserProfile());
        login(payload)
          .then((res) => {
            // Login success
            storeToken(res.data);
            dispatch(actions.authProcess(res));
            reduxStore.dispatch(fetchUserProfileSuccess(res));
          })
          .catch((error) => {
            handleAuthError(error);
          });
      },

      isAuthenticated: () => {
        if (isBrowser()) {
          return !!getData('sjwt');
        } else {
          return false;
        }
      },

      logout: () => {
        doLogout()
      },

      // Successfully logged with the provider
      // Now logging with strapi by using the access_token (given by the provider) in props.location.search
      providerAuthLogin: (provider, params) => {
        reduxStore.dispatch(resetUserProfile());
        providerLogin({ provider: provider, params: params })
          .then((res) => {
            // Login success
            storeToken(res.data);
            dispatch(actions.authProcess(res));
            reduxStore.dispatch(fetchUserProfileSuccess(res));
          })
          .catch((error) => {
            handleError(error)
          });
      },

      forgottenPassword: async (payload) => {
        dispatch(actions.authLoading());
        await forgotPassword(payload)
          .then((res) => {
            // Login success
            dispatch(actions.forgotPassword(res));
          })
          .catch((error) => {
            handleAuthError(error);
          });
      },

      verifyOtpPassword: async (payload) => {
        dispatch(actions.authLoading());
        await verifyOtpPassword(payload)
          .then((res) => {
            // OTP Login success

            // set token in session
            setUserSession();
            dispatch(actions.verifyOtpPassword(res));
          })
          .catch((error) => {
            handleAuthError(error);
          });
      },
      sendLoginOtp: async (payload) => {
        if (checkIfCanOtpSend()) {
          dispatch(actions.updateState({otpsendLoading: true}));
          await sendLoginOtp(payload)
            .then((res) => {
              dispatch(actions.updateState({otpsendLoading: false}));
              setOtpSentFlag();
              // OTP Login success
              dispatch(actions.sendLoginOtp(res));
            })
            .catch((error) => {
              // dispatch(actions.updateState({otpsendLoading: false}));
              handleAuthError(error);
            });
        }
      },

      resetPassword: async (payload) => {
        dispatch(actions.authLoading());

        const trackData = payload?.trackData;

        // remove tmp params
        if (payload?.trackData)
          delete payload.trackData

        if (payload?.showPassword)
          delete payload.showPassword

        // resetPassword
        await resetPassword(payload)
          .then((res) => {
            // Login success
            // if uid the this create password, update the status
            if (trackData && typeof trackData === 'function') {
              trackData()
            }
            if (payload?.uid && payload?.email_date) {
              createPassTrack({
                  uid: payload.uid,
                  email_date: payload.email_date,
              })
            } else if (payload?.uid) {
              createPassTrack({ uid: payload.uid })
            }
            storeToken(res.data);
            dispatch(actions.authProcess(res));
            reduxStore.dispatch(fetchUserProfileSuccess(res));
          })
          .catch((error) => {
            handleAuthError(error);
          });
      },

      emailConfirmation: async (payload) => {
        await emailConfirmation(payload)
          .then((res) => {
            // Login success
            storeToken(res.data);
            dispatch(actions.authProcess(res));
            reduxStore.dispatch(fetchUserProfileSuccess(res));
          })
          .catch((error) => {
            handleError(error)
          });
      },

      getUserProfile: async (payload) => {
        await getUserProfile(payload)
          .then((res) => {
            storeUserData(res.data);

            dispatch(actions.getUserData(res));
          })
          .catch((error) => {
            handleError(error)
          });
      },
      getPendingActionsCount: async (payload) => {
        getPendingActionCount(payload).then((res) => {
          dispatch(actions.getPendingActionCount(res.data));
        })
        .catch((error) => {
          handleError(error)
        });
      },
      resetMessage: async (payload) => {
        dispatch(actions.resetMessage(payload));
      },

      updateMessage: async (payload) => {
        dispatch(actions.updateMessage(payload));
      },
      createUserobject: (payload) => {
        // const data = { ...payload, type_id: md5(payload.params) };
        payload.type_id = md5(payload.params);
        createUserobject(payload).then((res) => {
          // post message here
        });
      },
      createUserobjectNoState: async (payload) => {
        // const data = { ...payload, type_id: md5(payload.params) };
        payload.type_id = md5(payload.params);
        return await createUserobject(payload);
      },
      hasPersona: async () => {
          const test = await hasPersona()
            // .then((res) => {
            //     console.log("res => ", res);
            //   // storeUserData(res.data);
            //   dispatch(actions.updatePersona(res));
            // })
            // .catch((error) => {
            //   handleError(error)
            // });
            return test.data;
      },
      updateState: (payload) => {
          dispatch(actions.updateState(payload));
      },
      providerLogin: (provider) => {
        if (isBrowser()) {
          window.location.href = `${baseURL}${API_PREFIX}/connect/${provider}`;
        }
      },
      updatePersona: (persona) => {
          dispatch(actions.updatePersona(persona.profile_persona));
          reduxStore.dispatch(updateProfileState(persona));
      },
      updateLastLogin: async (payload) => {
        updateLastLogin(payload).then((res) => {
          console.log(res);
        })
        .catch((error) => {
          handleError(error)
        });
      },
  })
};

export default services;

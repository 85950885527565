import { get } from 'lodash';
import { createSlice } from "@reduxjs/toolkit"
import { getUserPicture, getUser, storeUserData } from '../../services/store/utils';

const initialState = {
  success: false,
  loading: false,
  preference_success: false,
  complete_profile_success: false,
  error: false,
  profile: {},
  user: getUser(),
  message: '',
  status: ''
};

const collectUserDetails = (user) => {
  return {
    name: user.forename || user.username || user.email,
    uid: user.id,
    contact_id: get(user, 'contact_id[0]', false),
    picture: getUserPicture(user)
  }
}

const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    fetchUserProfile: (state, payload) => {
      let userData = payload.data;
      return {
        ...state,
        jwt: userData.jwt,
        user: {
          ...userData.user,
          ...collectUserDetails(userData.user),
        },
        loading: false,
        success: true,
        error: false,
        status: payload.status,
        message: ''
      }
    },
    updateUserProfile: (state, payload) => {
      let data = payload.data;
      return {
        ...state,
        success: true,
        loading: false,
        preference_success: false,
        complete_profile_success: false,
        error: false,
        profile: data,
        user: {
          ...data.user,
          name: data.forename || data.username || data.email,
          uid: data.id,
          contact_id: get(data, 'contact_id[0]', false),
          picture: getUserPicture(data.user)
        },
        message: payload?.message,
        status: payload.status
      }
    },
    fetchUserProfileSuccess: (state, type ) => {
      let userData = type.payload.data;
      return {
          ...state,
          jwt: userData.jwt,
          user: {
              ...userData.user,
              name: userData.user.forename || userData.user.username || userData.user.email,
              uid: userData.user.id,
              contact_id: get(userData.user, 'contact_id[0]', false),
              picture: getUserPicture(userData.user)
          },
          loading: false,
          success: true,
          error: false,
          status: type.payload.status,
          message: ''
      }
    },
    updateProfileState: (state, action) => {
      let user = state.user;
      let profile = state.profile;
      storeUserData(action.payload)
      return {
        ...state,
        user: {
          ...user,
          ...action.payload,
          ...collectUserDetails(action.payload),
        },
        profile: {
          ...profile,
          ...action.payload,
          ...collectUserDetails(action.payload),
        }
      }
    },
    resetUserProfile: (state, payload) => {
      return {
        ...state,
        profile: {}
      }
    }
  }
})

// Action creators are generated for each case reducer function
export const { fetchUserProfile, updateUserProfile, fetchUserProfileSuccess, updateProfileState, resetUserProfile } = profileSlice.actions

export default profileSlice.reducer



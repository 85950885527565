import { get } from 'lodash';
import { getUserPicture } from '../utils';

const Reducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN_PROCESS':
      let data = action.payload.data;
      return {
        ...state,
        jwt: data.jwt,
        user: {
          ...data.user,
          name: data.user.forename || data.user.username || data.user.email,
          uid: data.user.id,
          contact_id: get(data.user, 'contact_id[0]', false),
          picture: getUserPicture(data.user)
        },
        loading: false,
        success: true,
        error: false,
        status: action.payload.status,
        message: ''
      };

    case 'LOADING':
      return {
        ...state,
        success: false,
        loading: true,
        error: false
      };

    case 'LOGIN_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        message: get(action.payload, 'message[0].messages[0].message', '') || get(action.payload, 'message', false),
        status: action.payload.status
      };

    case 'API_ERROR':
        return {
          ...state,
          loading: false,
          error: true,
          message: action.payload.message,
          status: action.payload.status
        };

    case 'TOKEN_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload.message,
        status: action.payload.status
      };

    case 'VERIFY_EMAIL':
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        status: action.payload.status,
        message: 'Account confirmation Email send. Please check your inbox'
      };

    case 'PROVIDER_LOGIN_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload.message,
        status: action.payload.status
      };

    case 'USER_LOGOUT':
      return {
        user: null,
        loading: false,
        error: false
      };

    case 'FORGOT_PASSWORD':
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        message: action.payload.data.ok
          ? 'Please check your Inbox to reset your password'
          : 'Somthing went wrong',
        status: action.payload.status
      };

    case 'GET_USER_DATA':
      data = action.payload.data;
      return {
        ...state,
        profile: data,
        user: {
          name: data.forename || data.username || data.email,
          job_title: data?.job_title,
          uid: data.id,
          contact_id: get(data, 'contact_id[0]', false)
        },
        success: true,
        loading: false,
        error: false,
        message: '',
        status: action.payload.status
      };

      case 'RESET_MESSAGE':
        return {
          ...state,
          success: false,
          loading: false,
          error: false,
          message: false,
        };

    case 'UPDATE_MESSAGE':
      return {
        ...state,
        success: false,
        loading: false,
        error: false,
        pendingItemsCount: (action?.payload?.pendingItemsCount || 0),
        message: (action?.payload?.message || "Profile Updated")
      };

      case 'GET_PENDING_ACTIONS_COUNT':
        return {
          ...state,
          pendingItemsCount: (action?.payload ? action.payload.count : 0), //Math.floor(Math.random() * 100) + 1,
          pendingItemsCountCall: true
        };

    case 'PROFILE_UPDATE':
      data = action.payload.data;
      return {
        ...state,
        success: true,
        loading: false,
        error: false,
        profile: data,
        user: {
          name: data.user.forename || data.user.username || data.user.email,
          job_title: data.user?.job_title,
          uid: data.user.id,
          contact_id: get(data.user, 'contact_id[0]', false)
        },
        message: action.payload.data ? 'Profile Updated' : action.payload.data,
        status: action.payload.status
      };

    case 'SERVICE_ERROR':
      return {
        ...state,
        loading: false,
        error: true,
        message: action.payload.message,
        status: action.payload.status
      };

    case 'UPDATE_STATE':
      return {
        ...state,
        ...action.payload,
      };

    case 'UPDATE_PERSONA':
        return {
          ...state,
          hasPersona:action.payload.data
        };
    case 'SEND_LOGIN_OTP':
      return {
        ...state,
        otpsendLoading: false
      };
    case 'VERIFY_OTP_PASSWORD':
      return {
        ...state,
        success: true,
        loading: false,
        error: false
      };


    default:
      return state;
  }
};

export default Reducer;

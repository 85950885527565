import axios from "axios"
import { apiUrl, getToken, querystring } from "./utils"

const baseURL = apiUrl()
const MYACCOUNT_API_PREFIX = process.env.GATSBY_MYACCOUNT_API_PREFIX || ""
const API_PREFIX = process.env.GATSBY_API_PREFIX || ""

// const CancelToken = axios.CancelToken;
// let cancel;

/**
 * Auth
 */
// Without header call
export const instance = axios.create({
    baseURL: baseURL,
})

// With header call
export const authInstance = axios.create({
    baseURL: baseURL,
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
})

export const authInstanceDownload = axios.create({
    baseURL: baseURL,
    headers: {
        Authorization: `Bearer ${getToken()}`,
    },
    responseType: "blob",
})

export const authInstanceFun = (useKey = false) => {
    let token = getToken()
    if (useKey) token = getToken() || process.env.GATSBY_API_KEY
    return axios.create({
        baseURL: baseURL,
        headers: {
            Authorization: `Bearer ${token}`,
        },
    })
}

// Updating authtoken in authInstance after login/register
const updateToken = authToken => {
    authInstance.defaults.headers["Authorization"] = `Bearer ${authToken}`
}

const login = values => {
    // Call The login function
    return instance.post(`${API_PREFIX}/auth/local`, {
        identifier: values.email,
        password: values.password,
    })
}

const register = values => {
    // Call The login function
    return instance.post(`${API_PREFIX}/auth/local/register`, {
        ...values,
        username: values.email,
    })
}

const providerLogin = values => {
    // Call The login function
    return instance.get(
        `${API_PREFIX}/stb-users/auth/${values.provider}/callback${values.params}`
    )
}

const forgotPassword = async values => {
    // Call The login function
    return await instance.post(`${API_PREFIX}/auth/forgot-password`, values)
}

const emailConfirmation = async params => {
    // Call The login function
    // return await instance.get(`/auth/email-confirmation?${querystring(params)}`); // core
    return await instance.get(
        `${API_PREFIX}/auth/confirm-email?${querystring(params)}`
    ) // custom
}

const resetPassword = async values => {
    // Call The login function
    return await instance.post(`${API_PREFIX}/auth/reset-password`, values)
}

const sendLoginOtp = async values => {
    // Call The login function
    return await authInstanceFun().post(
        `${API_PREFIX}/stb-users/send-login-otp`,
        values
    )
}

const verifyOtpPassword = async values => {
    // Call The login function
    return await authInstanceFun().post(
        `${API_PREFIX}/stb-users/verify-otp-password`,
        values
    )
}

const updateProfile = params => {
    return authInstanceFun().put(`${API_PREFIX}/users/${params.id}`, params)
}

const customUpdateProfile = params => {
    return authInstanceFun().post(
        `${API_PREFIX}/stb-users/updateprofile`,
        params
    )
}

const updateContact = params => {
    return authInstanceFun().put(
        `${API_PREFIX}/stb-users/updatecontact`,
        params
    )
}

const updateLastLogin = params => {
    return authInstanceFun().put(
        `${API_PREFIX}/stb-users/updatelastlogin`,
        params
    )
}

const getUserProfile = params => {
    return authInstanceFun().get(`${API_PREFIX}/users/me`, params)
}

const getIdentityDocuments = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/identityDocuments?${querystring(
            params
        )}`
    )
}

const hasPersona = () => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/has-personas`
    )
}

/**
 * User objects
 */

const getUserobjects = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/stb-users/userobjects?${querystring(params)}`
    )
}

const createUserobject = params => {
    return authInstanceFun().post(`${API_PREFIX}/stb-users/userobjects`, params)
}

const updateUserobject = params => {
    return authInstanceFun().put(
        `${API_PREFIX}/stb-users/userobjects/${params.id}`,
        params
    )
}

const deleteUserobject = params => {
    return authInstanceFun().delete(
        `${API_PREFIX}/stb-users/userobjects/${params.id}`,
        params
    )
}

const updatePropertyPreference = params => {
    return authInstanceFun().post(
        `${API_PREFIX}/stb-users/propertypreference`,
        params
    )
}

const getProperties = params => {
    return authInstanceFun().get(`/properties?${querystring(params)}`)
}

const getCustomProperties = params => {
    return authInstanceFun().post(`/properties/search`, params)
}

const unsubscribe = params => {
    return instance.put(
        `${API_PREFIX}/stb-users/alert/property/unsubscribe`,
        params
    )
}

const getProperty = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.id}`
    )
}

/**
 * Form
 */

const postForm = params => {
    if (params?.useKey) {
        delete params.useKey
        return authInstanceFun(true).post(
            `${API_PREFIX}/stb-forms/forms`,
            params
        )
    } else {
        return authInstanceFun().post(`${API_PREFIX}/stb-forms/forms`, params)
    }
}

const getConfigValues = params => {
    const configAuthInstance = axios.create({
        baseURL: baseURL,
        headers: {
            Authorization: `Bearer ${getToken() || process.env.GATSBY_API_KEY}`,
        },
    })

    return configAuthInstance.get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/getconfigvalues/${
            params.type
        }?${querystring(params)}`
    )
}

const getTokenValues = params => {
    const configAuthInstance = axios.create({
        baseURL: baseURL,
        headers: {
            Authorization: `Bearer ${process.env.GATSBY_API_KEY}`,
        },
    })

    return configAuthInstance.post(
        `${API_PREFIX}/stb-forms/actionviatoken`,
        params
    )
}

const getAvailabilitySlots = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/getviewingavailabilities`
    )
}

const getOpenAvailabilitySlots = params => {
    const configAuthInstance = axios.create({
        baseURL: baseURL,
        headers: {
            Authorization: `Bearer ${process.env.GATSBY_API_KEY}`,
        },
    })
    return configAuthInstance.get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/getviewingavailabilities`
    )
}

const getAvailabilitySlotsByPostcode = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/getvaluationavailabilities/${params.property_type}/${params.postcode}`
    ) // :property_type/:postcode
}

const getAreas = () => {
    if (MYACCOUNT_API_PREFIX)
        return authInstanceFun().get(
            `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/getareas`
        )
    else
        return authInstanceFun().get(
            `${API_PREFIX}/${MYACCOUNT_API_PREFIX}getareas`
        )
}

/**
 * Myaccount
 */

// get
const getDashboardData = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/dashboard?${querystring(
            params
        )}`
    )
}

const getMypropertiesData = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/myproperties?${querystring(
            params
        )}`
    )
}

const getArchivedProperties = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/getarchivedproperties`
    )
}

const getMytenanciesData = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/mytenancies?${querystring(
            params
        )}`
    )
}

const getMyoffersData = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/myoffers?${querystring(
            params
        )}`
    )
}

const getMyappointmentsData = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/myappointments?${querystring(
            params
        )}`
    )
}

const getActivityData = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/activity?${querystring(
            params
        )}`
    )
}

const getPendingActionData = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/pendingactions?${querystring(
            params
        )}`
    )
}

const getPendingActionCount = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/pendingactionscount?${querystring(
            params
        )}`
    )
}

const getNewsItems = params => {
    let url = "blogs"
    if (params?.endpoint) {
        url = params?.endpoint
        delete params["endpoint"]
    }
    return authInstanceFun().get(`/${url}?${querystring(params)}`)
}

const countNewsItems = params => {
    let url = "blogs"
    if (params?.endpoint) {
        url = params?.endpoint
        delete params["endpoint"]
    }
    return authInstanceFun().get(`/${url}/count`)
}

const getMyNegotiatorData = () => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/negotiatordetails`
    )
}

// Post
const postAppointment = params => {
    return authInstanceFun().post(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/post-appointment`,
        params
    )
}

// Property

const getPropertyAppointments = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${
            params.property_id
        }/appointments?${querystring(params)}`
    )
}

const getPropertyFeedbacks = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/feedbacks`
    )
}

const getPropertyOffers = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/offers`
    )
}

const getPropertyTenancies = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/tenancies`
    )
}

const getPropertyTransactions = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/transactions`
    )
}
const getTransactionStatement = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${
            params.property_id
        }/transactions-income-expense-report?${querystring(params)}`
    )
}
const getLandlordProperties = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/landlord-properties`
    )
}
const getPropertyTasks = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/tasks`
    )
}

const getWorksOrders = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/worksorders`
    )
}

const getTenancyDocuments = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/tenancydocuments`
    )
}

const getPropertyDocuments = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/propertydocuments`
    )
}

const downloadDocument = params => {
    authInstanceDownload.defaults.headers[
        "Authorization"
    ] = `Bearer ${getToken()}`
    return authInstanceDownload.get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/document/${params.document_id}/download`
    )
}

const getPropertyPendingActions = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/pendingactions`
    )
}

const getPropertyRecentActivities = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/activity`
    )
}

const getPropertyStats = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${
            params.property_id
        }/stats?${querystring(params)}`
    )
}

const getHasAppointments = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/hasAppointments`
    )
}

const getCalendarList = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/getcalendarlist?${querystring(
            params
        )}`
    )
}

const hideProperty = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/hideProperty`
    )
}

const unHideProperty = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/unHideProperty`
    )
}

const getActionAllowed = params => {
    return authInstanceFun().get(
        `${API_PREFIX}/${MYACCOUNT_API_PREFIX}myaccount/property/${params.property_id}/actionAllowed`
    )
}

const createPassTrack = async params => {
    if (params.email_date) {
        return await axios.get(
            `${baseURL}/stb-users/${params.uid}/3/tracking-pixel.png?email_date=${params.email_date}`
        )
    } else {
        return await axios.get(
            `${baseURL}/stb-forms/${params.uid}/3/tracking-pixel.png`
        )
    }
}

const loginWithGoogleOneTabResponse = params => {
    return instance.post(
        `${API_PREFIX}/stb-users/google-one-tab-login/${params.token}`
    )
}

const getCall = url => {
    async function fetchData() {
        return await axios.get(`${url}`)
    }
    return fetchData()
}

export {
    // myaccount
    getDashboardData,
    getMypropertiesData,
    getArchivedProperties,
    getMytenanciesData,
    getMyoffersData,
    getMyappointmentsData,
    postAppointment,
    getActivityData,
    getPendingActionData,
    getPendingActionCount,
    getNewsItems,
    countNewsItems,
    getMyNegotiatorData,

    // Property
    getPropertyAppointments,
    getPropertyFeedbacks,
    getPropertyOffers,
    getPropertyTenancies,
    getPropertyTransactions,
    getTransactionStatement,
    getLandlordProperties,
    getPropertyTasks,
    getWorksOrders,
    getTenancyDocuments,
    getPropertyDocuments,
    getPropertyPendingActions,
    getPropertyRecentActivities,
    getPropertyStats,
    getHasAppointments,
    getCalendarList,
    hideProperty,
    unHideProperty,
    downloadDocument,

    // auth
    updateToken,
    login,
    register,
    getUserobjects,
    providerLogin,
    forgotPassword,
    resetPassword,
    getUserProfile,
    updateProfile,
    customUpdateProfile,
    updateContact,
    updateLastLogin,
    getProperties,
    getCustomProperties,
    getProperty,
    createUserobject,
    updateUserobject,
    deleteUserobject,
    updatePropertyPreference,
    emailConfirmation,
    postForm,
    getConfigValues,
    getAvailabilitySlots,
    getOpenAvailabilitySlots,
    getAvailabilitySlotsByPostcode,
    getAreas,
    unsubscribe,
    getCall,
    hasPersona,
    getIdentityDocuments,
    getActionAllowed,
    getTokenValues,
    createPassTrack,
    loginWithGoogleOneTabResponse,
    verifyOtpPassword,
    sendLoginOtp,
}

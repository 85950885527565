import { doLogout, getToken } from "../services/store/utils"

const collectResponseMessage = (result, error, isError, successMessage) => {
    let message = ""
    let severity = "success"
    if (isError) {
        severity = "error"
        switch (error?.status || error?.originalStatus) {
            case "FETCH_ERROR":
            case 500:
                message = "Something went wrong. Please try again."
                break
            case 400:
                console.log("messageDialog => ", error?.data?.message)
                message = error?.message || error?.data?.message || error?.data?.error?.message
                // doLogout()
                break
            case 401:
                console.log("messageDialog => ", error?.data?.message)
                message = error?.data?.message
                // doLogout()
                break
            case 405:
            case "PARSING_ERROR":
                console.log("messageDialog => ", error?.error)
                message = error?.data || error?.error
                break
            default:
                message = ""
        }

        return {
            message: message,
            severity: severity,
        }
    }

    if (result.status === "fulfilled") message = successMessage
    else {
        severity = "error"
        switch (result.status) {
            case "FETCH_ERROR":
            case 500:
                message = "Something went wrong. Please try again."
                break
            case "rejected":
                message = result.error?.data?.message
                break
            case 401:
                console.log("messageDialog => ", error?.data?.message)
                message = error?.data?.message
                // doLogout()
                break
            default:
                message = ""
        }
    }

    const messageDialog = {
        message: message,
        severity: severity,
    }
    return messageDialog
}

const getHeader = () => {
    return {
        Authorization: `Bearer ${getToken()}`,
    }
}

const handleApiError = async queryFulfilled => {
    try {
        await queryFulfilled
    } catch (err) {
        const statusCode = err?.error?.data?.statusCode || err?.error?.status

        // if (statusCode === 401) doLogout()

        switch (statusCode) {
            case 401:
                doLogout()
                break
            case "FETCH_ERROR":
                // doLogout()
                break

            default:
                break
        }
        console.log(
            "Error Fetching post...",
            err,
            err?.error?.status
        )
    }
}

export { collectResponseMessage, getHeader, handleApiError }
